import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { getMerchantConfig } from "features/config/configSlice";

function BankForm({handleSubmitBankDetails, submittingDetails}) {
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit
  } = useForm();
  const merchantConfig = useSelector(getMerchantConfig);
  let accnum = watch("accountnum");
  return (
    <form onSubmit={handleSubmit(handleSubmitBankDetails)} style={{padding: '20px'}}>
      <Controller
        control={control}
        defaultValue=""
        rules={{ required: true, pattern: /^\d{9,18}$/ }}
        name="accountnum"
        render={({ field }) => (
          <TextField
            variant="outlined"
            autoFocus
            id="accountnum"
            error={Boolean(errors.accountnum)}
            helperText={
              errors.accountnum
                ? errors.accountnum.type === "pattern"
                  ? "Account number must be more than 9 numbers"
                  : errors.accountnum.type === "required"
                  ? "Account Number is required"
                  : ""
                : ""
            }
            label="Account Number"
            defaultValue=""
            fullWidth
            inputProps={{ type: "text" }}
            style={{ marginTop: "20px" }}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        rules={{
          required: true,
          pattern: /^\d{9,18}$/,
          validate: (value) =>
            value === accnum || "Account numbers do not match",
        }}
        name="confirmAccountnum"
        render={({ field }) => (
          <TextField
            variant="outlined"
            id="confirmAccountnum"
            error={Boolean(errors.confirmAccountnum)}
            helperText={
              errors.confirmAccountnum
                ? errors.confirmAccountnum.type === "pattern"
                  ? "Account number must be more than 9 numbers"
                  : errors.confirmAccountnum.type === "required"
                  ? "Account Number is required"
                  : errors.confirmAccountnum.type === "validate"
                  ? "Account numbers do not match"
                  : ""
                : ""
            }
            label="Re-enter Account Number"
            defaultValue=""
            fullWidth
            inputProps={{ type: "text" }}
            style={{ marginTop: "20px" }}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        rules={{ required: true, minLength: 2 }}
        name="name"
        render={({ field }) => (
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            label="Account Holder Name"
            error={Boolean(errors.name)}
            helperText={
              errors.name
                ? errors.name.type === "minLength"
                  ? "Name length must be more than 2"
                  : "Name is required"
                : ""
            }
            inputProps={{ type: "text" }}
            style={{ marginTop: "20px" }}
            {...field}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue=""
        rules={{
          required: true,
          pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/,
        }}
        name="ifsc"
        render={({ field }) => (
          <TextField
            variant="outlined"
            fullWidth
            id="ifsc"
            label="IFSC"
            error={Boolean(errors.ifsc)}
            helperText={
              errors.ifsc
                ? errors.ifsc.type === "pattern"
                  ? "IFSC code doesn't match required pattern"
                  : "IFSC code is required"
                : ""
            }
            inputProps={{ type: "text" }}
            style={{ marginTop: "20px" }}
            {...field}
          />
        )}
      />

      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={submittingDetails}
        style={{
          padding: "1rem",
          borderRadius: !merchantConfig.use_rounded_edge_buttons
            ? "0px"
            : "4px",
          margin: "40px auto",
          display: "block",
          cursor: "pointer",
          textTransform: "capitalize",
        }}
      >
        {submittingDetails ? "Submitting..." : "Submit" }
      </Button>
    </form>
  );
}

export default BankForm;
